import Vue from "vue";
import Router from "vue-router";
import axios from "axios";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard"),
				},
				{
					path: "drive",
					name: "mine",
					component: () => import("@/view/pages/Drive"),
				},
				{
					path: "shared-with-me",
					name: "shared-with-me",
					component: () => import("@/view/pages/SharedWithMe"),
				},
				{
					path: "recent",
					name: "recent",
					component: () => import("@/view/pages/Recent"),
				},
				{
					path: "starred",
					name: "starred",
					component: () => import("@/view/pages/Starred"),
				},
				{
					path: "bin",
					name: "bin",
					component: () => import("@/view/pages/Bin"),
				},
				{
					path: "users",
					name: "Users",
					component: () => import("@/view/pages/Users"),
				},
				{
					path: "activity",
					name: "activity",
					component: () => import("@/view/pages/ActivityLog"),
				},
				{
					path: "user-detail",
					name: "UserDetail",
					component: () => import("@/view/pages/UserDetail"),
				},
				{
					path: "tag",
					name: "tag",
					component: () => import("@/view/pages/Tags"),
				},
				{
					path: "tag/:uuid/detail",
					name: "tag-detail",
					component: () => import("@/view/pages/Tag-Detail"),
				},
				{
					path: "setting",
					name: "Setting",
					component: () => import("@/view/pages/Setting"),
				},
				{
					path: "pdf",
					name: "pdf-preview",
					component: () => import("@/view/pages/Pdf-Preview"),
				},
				{
					path: "pdf2",
					name: "pdf2-preview",
					component: () => import("@/view/pages/Pdf-Preview2"),
				},
				/*{
					path: "clients",
					name: "clients",
					component: () => import("@/view/pages/client/Users.vue"),
				},
				{
					path: "clients-detail/:id",
					name: "clients-detail",
					component: () => import("@/view/pages/client/UserDetail.vue"),
				}*/
			],
		},
		/* {
			path: "/admin",
			name: "master-admin",
			component: () => import("@/view/pages/admin/Dashboard.vue"),
			children: [
				{
					path: "users",
					name: "admin-users",
					component: () => import("@/view/pages/admin/Users.vue"),
				},
				{
					path: "user-detail/:id",
					name: "admin-user-detail",
					component: () => import("@/view/pages/admin/UserDetail.vue"),
				}
			]
		}, */
		{
			path: "/custom-error",
			name: "error",
			component: () => import("@/view/pages/error/Error"),
			children: [
				{
					path: "error-1",
					name: "error-1",
					component: () => import("@/view/pages/error/Error-1"),
				},
			],
		},
		{
			name: "authenticate",
			path: "/",
			component: () => import("@/view/pages/auth/Authenticate"),
			children: [
				{
					path: "login",
					name: "login",
					component: () => import("@/view/pages/auth/Login"),
				},
				{
					path: "reset-password",
					name: "reset-password",
					component: () => import("@/view/pages/auth/Reset-Password"),
				},
			],
		},
		{
			path: "*",
			redirect: "/404",
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1"),
		},
		{
			path: "/confirm-consent",
			name: "consent",
			component: () => import("@/view/pages/error/Consent"),
			beforeEnter: async (to, from, next) => {
				const token = to.query.to;
				const userId = to.query.oauth;

				if (!token || !userId) {
					next({ name: "404" });
					return;
				}

				try {
					const response = await axios.post("/verify-consent", {
						token: token,
						userId: userId,
					});
					if (response.data.isValid) {
						to.meta.consentData = response.data;
						next();
					} else {
						next({ name: "404" });
					}
				} catch (error) {
					console.error("Error verifying consent:", error);
					next({ name: "404" });
				}
			},
		},
	],
});
